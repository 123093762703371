<!-- sidebar -->
<template>
  <div class="c-sidebar">
    <div class="c-sidebar-top">
      <!-- sidebar-logo -->
      <router-link :to="{ name: 'projectList' }">
        <div class="c-sidebar-logo" />
      </router-link>
      <!-- sidebar-nav -->
      <transition-toggle-contents>
        <div
          v-if="!accountInfoLoading && !logoutLoading"
          class="c-sidebar-list-wrap"
        >
          <router-link
            class="c-sidebar-list-item"
            :to="{ name: 'myAccountInfo' }"
          >
            <text-with-icon
              class="c-sidebar-list-item-title"
              iconName="option"
              :text="$t('common.sidebarTop.accountInfo')"
              color="gray"
              size="small"
            />
            <texts class="c-sidebar-list-item-text" :text="accountInfo.name" />
          </router-link>
          <router-link
            class="c-sidebar-list-item"
            :to="{ name: 'organization' }"
          >
            <template v-if="accountInfo.organizationInfo != null">
              <text-with-icon
                class="c-sidebar-list-item-title"
                :text="$t('common.sidebarTop.organization')"
                iconName="team"
                color="gray"
                size="small"
              />
              <texts
                class="c-sidebar-list-item-text"
                :text="this.accountInfo.organizationInfo.name"
              />
            </template>
            <template v-else>
              <text-with-icon
                class="c-sidebar-list-item-title"
                :text="$t('common.sidebarTop.organization')"
                iconName="team"
                color="gray"
                size="small"
              />
              <texts
                class="c-sidebar-list-item-text"
                :text="$t('common.sidebarTop.noOrganization')"
                color="gray"
              />
            </template>
          </router-link>
          <a
            class="c-sidebar-list-item"
            href="https://www.matrixflow.net/matrixflow/contact-basic-plan"
          >
            <text-with-icon
              class="c-sidebar-list-item-title"
              :text="$t('common.sidebarTop.plan')"
              iconName="upgrade"
              color="gray"
              size="small"
            />
            <texts
              class="c-sidebar-list-item-text"
              :text="$t('account.setting.info.accountPlan.' + accountInfo.plan)"
            />
          </a>
        </div>
        <loading-icon v-else class="c-sidebar-list-loading" />
      </transition-toggle-contents>
    </div>
    <!-- sidebar-logout -->
    <div class="c-sidebar-customblock">
      <!-- TODO マニュアルを作成したあとにリンクを設定する -->
      <texts class="c-sidebar-customblock-text" color="gray" :size="12">
        {{ $t('customblock.goCustomBlock.description')
        }}<!--<a href="https://www.matrixflow.net/manual/">{{
          $t('common.manual.text')
        }}</a>{{ $t('customblock.goCustomBlock.description-second') }}-->
      </texts>
      <button-main
        class="c-sidebar-customblock-button"
        type="sub"
        size="min"
        fontSize="small"
        :text="$t('customblock.goCustomBlock.title')"
        @click="goCustomblock"
      />
    </div>
    <!-- sidebar-logout -->
    <div class="c-sidebar-logout-wrap">
      <button class="c-sidebar-logout-inner" @click="logout()">
        <text-with-icon
          iconName="logout"
          :text="$t('common.sidebarTop.logout')"
        />
      </button>
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text.vue'
import LoadingIcon from '@/components/atoms/loading-icon.vue'
import textWithIcon from '@/components/molecules/text-with-icon.vue'
import TransitionToggleContents from '@/components/molecules/transition-toggle-contents'
import { mapGetters, mapActions } from 'vuex'
import ButtonMain from '../atoms/button-main.vue'

export default {
  components: {
    texts,
    LoadingIcon,
    textWithIcon,
    TransitionToggleContents,
    ButtonMain
  },
  data() {
    return {
      logoutLoading: false
    }
  },
  computed: {
    ...mapGetters('auth', ['accountInfo', 'accountInfoLoading']),
    customblockShown() {
      return ['basic', 'enterprise_lite'].includes(this.accountInfo?.plan)
    }
  },
  methods: {
    ...mapActions('auth', ['signout']),
    async logout() {
      this.logoutLoading = true
      await this.signout()
    },
    goCustomblock() {
      this.$router.push({
        name: 'customblockList'
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
// サイドバー
.c-sidebar {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: adjustVW(240); // 12.5%
  height: 100vh;
  background: $background;
  z-index: 1000;
  &-logo {
    height: adjustVH(88);
    margin: 0 $space-small $space-base;
    @include logo(100%, 100%);
    background-position: center;
    transition: opacity $transition-base;
    will-change: opacity;
    &:hover {
      opacity: 0.5;
    }
  }
  &-top {
    flex-grow: 1;
  }
  &-list {
    &-item {
      display: block;
      padding: 0 $space-small;
      margin: 0 0 $space-large;
      transition: opacity $transition-base;
      will-change: opacity;
      &:hover {
        opacity: 0.5;
      }
      &-title {
        margin-bottom: $space-text;
      }
    }
    &-loading {
      padding: 0 $space-small;
    }
  }
  &-customblock {
    padding: $space-sub;
    margin: 0 $space-sub;
    background: $green-lite;
    border-radius: adjustVW(4);
    &-text {
      margin-bottom: $space-sub;
      white-space: pre-line;
      > a {
        margin: 0 $space-min;
        color: $blue;
      }
    }
    &-button {
      width: 100%;
    }
  }
  &-logout-wrap {
    flex-grow: 0;
    padding: 0 $space-small;
    margin: $space-large 0;
    transition: opacity $transition-base;
    will-change: opacity;
    &:hover {
      opacity: 0.5;
    }
  }
}
</style>
